import toastr from "toastr";
import { axiosInstance } from ".";
export const getTimelinePosts = async (page) => {
  try {
    const response = await axiosInstance.get(`/timeline?page=${page}&limit=10`);
    if (response) return response.data;
  } catch (error) {
    console.error(error);
    toastr.error(error?.response?.data?.message || error?.message);
  }
};
