import React, { useState, useEffect, useMemo, useRef } from "react";
import clsx from "clsx";
import {
  Box,
  CircularProgress,
  Grid,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core";
import "react-multi-carousel/lib/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { useShallowRouting } from "@utils/customHook";
import { getAvailableUsers } from "@services/user";
import {
  setIsFirstTimeGetChannelsSuccess,
  setNewChatInfo,
} from "@reducers/chat";
import { AmptitudeEvents } from "@services/amptitude_events";
import { PAGE_VIEW } from "@services/amptitude_contants";
import { useRouter } from "next/router";
import { signout } from "@reducers/auth";
import { Helmet } from "react-helmet";
import { projectId } from "../../services/firebaseConfig.json";
import { getTimelinePosts } from "@services/timeline";
import PostItem from "./components/ImageVideo/PostItem";
import LoadingIndicator from "@components/LoadingIndicator";

const useStyles = makeStyles((theme) => ({
  dashboard: {
    width: "750px",
  },
  bannerLeft: {
    height: "240px",
    width: "124px",
    backgroundColor: theme.palette.grey.main,
    position: "fixed",
    left: "8px",
    marginTop: "16px",
  },
  bannerRight: {
    height: "240px",
    width: "124px",
    backgroundColor: theme.palette.grey.main,
    position: "fixed",
    right: "8px",
    marginTop: "16px",
  },
  dailyDeal: {
    "& .react-multi-carousel-dot-list": {
      marginTop: "24px",
    },
  },
  tab: {
    padding: "24px 0 16px",
    fontSize: "18px",
    color: theme.palette.grey.text,
    textTransform: "none",

    "&.Mui-selected": {
      color: theme.palette.primary.main,
    },
  },
  sectionTitle: {
    fontSize: 21,
    fontWeight: "bold",
    lineHeight: "36px",
    marginLeft: 10,
  },
  relatedCarousel: {
    "& .react-multi-carousel-track": {
      margin: "0 -4px",
    },
    "& .react-multi-carousel-item": {
      padding: "0 8px",
    },
    "& .react-multiple-carousel__arrow": {
      background: theme.palette.primary.main,
      opacity: 0.8,
      top: 92,
    },
    "& .react-multiple-carousel__arrow--right": {
      right: 20,
    },
    "& .react-multiple-carousel__arrow--left": {
      left: 12,
    },
  },
  sectionPopular: {
    marginTop: 32,
    "& .section-header": {
      background: theme.palette.initial.main,
      padding: 16,
      borderRadius: "22px 22px 0 0",
    },
  },
  borderBottomProducts: {
    borderBottom: "5px solid #69CDAE !important",
    borderBottomWidth: "2px !important",
    width: "27%",
  },
  postBox: {
    width: "1000px !important",
    height: "160px !important",
    margin: "auto",
    boxShadow: "0px 20px 60px rgba(241, 244, 248, 0.5) !important",
    borderRadius: "4px !important",
    border: "1px solid rgba(0, 0, 0, 0.2)",
    boxSizing: "border-box !important",
    alignItems: "center",
  },
  postHeader: {
    margin: "24px 28px",
    fontSize: "16px",
    alignItems: "center",
  },
  textField: {
    margin: "0 28px !important",
  },
  iconContainer: {
    justifyContent: "flex-end",
    gap: "0.5rem",
    display: "flex",
  },
  sideBarBox: {
    height: "100%",
    margin: "0 auto",
    backgroundColor: "white",
    borderRadius: 20,
  },
  mainContainer: {
    margin: "0 auto",
    maxWidth: "1250px",
    marginBottom: 50,
  },
}));

export default function DashboardContainer() {
  const classes = useStyles();
  const Router = useRouter();
  const dispatch = useDispatch();
  const [router, shallowQuery, onChangeShallowQuery] = useShallowRouting();
  const user = useSelector((state) => state.auth.user);

  const observerRef = useRef();

  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);


  useEffect(() => {
    if (user?.id && !user?.isVerified) {
      dispatch(signout());
      Router.push("/login");
    }
  }, [user?.id, user?.isVerified]);

  //event
  useEffect(() => {
    if (user?.id && user?.companyId) {
      const sendEvent = async () => {
        return await AmptitudeEvents(
          "Timeline",
          user?.id,
          PAGE_VIEW,
          window.location.href,
          user.companyId,
          user.companyName,
          "",
          "homepage",
          ""
        );
      };
      sendEvent();
    }
  }, [user?.id]);

  //companyData

  const isFirstTimeGetChannelsSuccess = useSelector(
    (state) => state.chat.isFirstTimeGetChannelsSuccess
  );

  const { chatbox } = shallowQuery;

  useEffect(() => {
    onChangeShallowQuery();
    if (chatbox && isFirstTimeGetChannelsSuccess) {
      handleSetNewChat();
    }
  }, [chatbox, isFirstTimeGetChannelsSuccess]);

  const handleSetNewChat = () => {
    getAvailableUsers({ keyword: chatbox, includes: "company" })
      .then((response) => {
        const { items: userList } = response;

        if (userList?.length) {
          dispatch(
            setNewChatInfo({
              companyId: userList[0].companyId,
              email: userList[0].email,
              fullName: userList[0].fullName,
              id: userList[0].id,
              photo: userList[0].photo,
              companySlug: userList[0]?.companySlug,
              companyName: userList[0]?.company?.companyName,
            })
          );
        }

        dispatch(setIsFirstTimeGetChannelsSuccess(false));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getPosts = async (page) => {
    setLoading(true);

    const response = await getTimelinePosts(page);

    setTotalPage(response?.metaData?.totalPage);
    setPosts((prevData) => [...prevData, ...response?.items]);

    setLoading(false);
  };
 
  useEffect(() => {
    if (page === 1) getPosts(1); 
  }, []); 
  useEffect(() => {
    if (totalPage !== undefined && (page > totalPage)) return;
    if (page > 1) getPosts(page);
  }, [page, totalPage]); 

  useEffect(() => {
    if (!observerRef.current) return; // Eğer ref bağlanmamışsa bekle
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          console.log("girdi")
          setPage((prevPage) => prevPage + 1);
        }
      },
      { threshold: 1 }
    );

    if (observerRef.current) observer.observe(observerRef.current);

    return () => {
      if (observerRef.current) observer.unobserve(observerRef.current);
    };
  }, []);

  return (
    <div >
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {projectId === "frupro-staging"
            ? "🟡Latest | FruPro"
            : "Latest | FruPro"}
        </title>
      </Helmet>

      <div
        style={{
          minHeight:1000,
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          gap: 10,
        }}
        className="container mb-5"
      >
        {posts.map((post, index) => {
          return <PostItem post={post} key={index} />;
        })}
      </div>

      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={20} />
        </div>
      ) : null}
      <div
        ref={observerRef}
        style={{ height: "50px", background: "transparent" }}
      />
    </div>
  );
}
