import React, { useRef, useState } from "react";
import ImageVideoViewModal from "./ImageVideoViewModal";

const VideoImagePostViewItem = ({ files, isOneImage, index, file }) => {
  const imgRef = (useRef < HTMLImageElement) | (null > null);
  const [zoomImage, setZoomImage] = useState(false);

  const imgHeight =
    imgRef?.current?.naturalWidth && imgRef?.current?.naturalHeight
      ? (600 / imgRef?.current?.naturalWidth) * imgRef?.current?.naturalHeight
      : 300;

  const onOpenDetail = () => {
    setZoomImage(true);
  };

  return (
    <div
      key={file?.id}
      onClick={!zoomImage ? onOpenDetail : () => null}
      className={`relative cursor-pointer hover:opacity-70 rounded-sm  border border-1 w-full`}
      style={{
        cursor: "pointer",
        height: isOneImage ? imgHeight : 300,
        backgroundImage: `url(${file?.name})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <ImageVideoViewModal
        file={file}
        open={zoomImage}
        setOpen={setZoomImage}
        files={files}
      />
    </div>
  );
};

export default VideoImagePostViewItem;
