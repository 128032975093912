import moment from "moment";
import React from "react";
import VideoImagePostView from "./VideoImagePostView";
import { useBlurText } from "../../hooks/useBlurText";

const PostItem = ({ post }) => {
  const text = useBlurText(post?.processed_post);

  return (
    <div style={{ borderRadius: 5, width: 600 }} className="p-3 bg-white">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 5,
        }}
      >
        <img
          style={{ borderRadius: "100%", width: 40, height: 40 }}
          src={post?.company_admin?.photo}
        />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span style={{ fontSize: 14 }}>{post?.company_admin?.fullName}</span>
          <span style={{ fontSize: 12, color: "gray" }}>
            {post?.company?.companyName}
          </span>
          <span style={{ fontSize: 10, color: "gray" }}>
            {moment(post?.post_date).format("DD/MM/YYYY HH:mm")}
          </span>
        </div>
      </div>

      <div>
        <span style={{ fontSize: 15 }}>{text}</span>
      </div>
      {post.images.length ? (
        <VideoImagePostView
          files={post.images.map((i) => ({
            name: i.url,
            id: i._id,
          }))}
        />
      ) : null}
    </div>
  );
};

export default PostItem;
