import React from "react";
import VideoImagePostViewItem from "./VideoImagePostViewItem";
import VideoImageViewSubItem from "./VideoImagePostViewSubItem";

const VideoImagePostView = ({ files }) => {
  return files.length > 4 ? (
    <>
      <div className="row g-3">
        {files.slice(0, 4).map((f, index) => {
          return (
            <div className="col-6" key={f?.id}>
              <VideoImagePostViewItem
                files={files}
                file={f}
                index={index}
              />
            </div>
          );
        })}
      </div>
      <div className="d-flex mt-3 gap-2">
        {files.slice(4, files.length).map((f, index) => {
          return (
            <VideoImageViewSubItem
              files={files}
              file={f}
              key={index}
              index={index}
            />
          );
        })}
      </div>
    </>
  ) : files.length === 3 ? (
    <div className="row g-3">
      {files.map((f, index) => {
        return index === 2 ? (
          <div className="col-12" key={f?.id}>
            <VideoImagePostViewItem
              files={files}
              file={f}
              index={index}
            />
          </div>
        ) : (
          <div className="col-6" key={f?.id}>
            <VideoImagePostViewItem
              files={files}
              file={f}
              index={index}
            />
          </div>
        );
      })}
    </div>
  ) : files.length === 4 ? (
    <div className="row  g-3">
      {files.map((f, index) => {
        return (
          <div className="col-6" key={f?.id}>
            <VideoImagePostViewItem
              files={files}
              file={f}
              index={index}
            />
          </div>
        );
      })}
    </div>
  ) : files.length === 2 ? (
    <div className="row  g-3">
      {files.map((f, index) => {
        return (
          <div className="col-6" key={f?.id}>
            <VideoImagePostViewItem
              files={files}
              file={f}
              index={index}
            />
          </div>
        );
      })}
    </div>
  ) : (
    <div className="row  g-3">
      {files.map((f, index) => {
        return (
          <div className="col-12" key={f?.id}>
            <VideoImagePostViewItem
              files={files}
              isOneImage
              file={f}
              index={index}
            />
          </div>
        );
      })}
    </div>
  );
};

export default VideoImagePostView;
