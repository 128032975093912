import React, { useMemo } from "react";
import Modal from "react-bootstrap/Modal";
import Carousel from "react-bootstrap/Carousel";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  modelContent: {
    padding: `${theme.spacing(4)}px 60px`,
    textAlign: "center",
    fontSize: "18px",
  },
  imageContent: {
    padding: "12px",
    height: "100%",
  },
  carouselPopupImage: {
    width: "100%",
    height: "500px",
    objectFit: "contain",
    borderRadius: "20px",
  },
  modalMainContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "rgba(255, 255, 255, 0.3)",
    backdropFilter: "blur(8px)",
  },
  isModalHeader: {
    border: "none",
  },
  topHeaderBox: {
    display: "flex",
    flexDirection: "column",
  },
  // Carousel navigation buttons styling
  carouselControlPrev: {
    "& .carousel-control-prev-icon": {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      borderRadius: "50%",
      width: "40px",
      height: "40px",
    },
    "&:hover .carousel-control-prev-icon": {
      backgroundColor: "rgba(0, 0, 0, 0.8)",
    },
  },
  carouselControlNext: {
    "& .carousel-control-next-icon": {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      borderRadius: "50%",
      width: "40px",
      height: "40px",
    },
    "&:hover .carousel-control-next-icon": {
      backgroundColor: "rgba(0, 0, 0, 0.8)",
    },
  },
}));

const ImageVideoViewModal = ({ open, setOpen, files, file }) => {
  const imageIndex = useMemo(() => {
    return files?.findIndex((f) => f?.id === file?.id);
  }, [files, file]);

  const classes = useStyles();

  return (
    <div>
      <Modal
        size="lg"
        show={open}
        onHide={() => setOpen(false)}
        animation={false}
        className={classes.modalMainContainer}
      >
        <Modal.Header
          className={classes.isModalHeader}
          closeButton
        ></Modal.Header>

        <div>
          <Modal.Body>
            <Carousel
              defaultActiveIndex={imageIndex}
              className={`${classes.carouselControlPrev} ${classes.carouselControlNext}`}
            >
              {files.map((item) => {
                return (
                  <Carousel.Item key={item._id}>
                    <img
                      className={classes.carouselPopupImage}
                      src={item.name}
                      alt="imageAlt"
                    />
                  </Carousel.Item>
                );
              })}
            </Carousel>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
};

export default ImageVideoViewModal;
