import { useEffect, useRef, useState } from "react";

export const useBlurText = (text) => {
  const textRef = useRef(null);
  const [textHeight, setTextHeight] = useState(0);

  const [isShowAllText, setIsShowAllText] = useState(false);

  const onShowAllTexts = () => {
    setIsShowAllText(true);
  };
  const onHideAllTexts = () => {
    setIsShowAllText(false);
  };

  useEffect(() => {
    if (textRef.current?.clientHeight) {
      setTextHeight(textRef.current?.clientHeight);
    }
  }, [isShowAllText, textRef]);

  if (!isShowAllText) {
    return (
      <div className="position-relative">
        <div
          className={`${
            textHeight && textHeight > 145 ? "overflow-hidden" : ""
          }`}
          style={{
            height: textHeight && textHeight > 145 ? "110px" : "auto",
            wordBreak: "break-word",
          }}
        >
          <div ref={textRef} style={{ fontSize: "14px" }}>
            {text}
          </div>
        </div>

        {textHeight && textHeight > 145 ? (
          <div
            className="position-absolute  start-0 end-0"
            style={{
              bottom: 25,
              height: "40px",
              background:
                "linear-gradient(to top, white, rgba(255, 255, 255, 0))",
            }}
          />
        ) : null}
        <div className="d-flex justify-content-end">
          {textHeight && textHeight > 145 ? (
            <span
              onClick={onShowAllTexts}
              className="text-primary text-decoration-underline small"
              style={{ cursor: "pointer", opacity: 0.8 }}
            >
              Show more..
            </span>
          ) : null}
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div style={{ fontSize: "14px" ,wordBreak:"break-word"}}>{text}</div>
        <span
          onClick={onHideAllTexts}
          className="small d-flex justify-content-end text-primary text-decoration-underline"
          style={{ cursor: "pointer", opacity: 0.8 }}
        >
          Show less
        </span>
      </div>
    );
  }
};
