import React, { useMemo, useState } from "react";
import ImageVideoViewModal from "./ImageVideoViewModal";

const VideoImageViewSubItem = ({ index, file, files }) => {
  const [zoomImage, setZoomImage] = useState(false);
  const fileIndex = useMemo(() => {
    return files.findIndex((f) => f?.id === file?.id);
  }, [files, file]);

  const onZoomImage = () => {
    setZoomImage(true);
  };
  return (
    <div
      onClick={zoomImage ? () => null : onZoomImage}
      className={`relative border border-1 rounded-sm  w-full`}
      style={{
        cursor:"pointer",
        height: 60,
        width: 60,
        backgroundImage: `url(${file?.name})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <ImageVideoViewModal
        file={file}
        open={zoomImage}
        setOpen={setZoomImage}
        files={files}
      />
    </div>
  );
};

export default VideoImageViewSubItem;
